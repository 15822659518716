html,
body {
  width: 100%;
  height: 100%;
  min-height: 100%;
  line-height: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Josefin Sans', sans-serif;
  font-weight: 400;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Ensures "scrollIntoView()" methods are smooth not "jumpy" */
  scroll-behavior: smooth;
}

/* ======================================== */
/*                   FONTS                  */
/* ======================================== */
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@700&family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.cdnfonts.com/css/open-sauce-one');

.font-josefin {
  font-family: 'Josefin Sans' !important;
}

.font-josefin-b {
  font-family: 'Josefin Sans' !important;
}

.font-opensauce {
  font-family: 'Open Sauce One';
}

@tailwind base;
@tailwind components;
@tailwind utilities;
.homepage-banner {
  background-image: url('./assets/images/homepage/banner-bg.jpg');
  background-size: cover;
  background-position: center;
}

.experience-bg {
  background-image: url('./assets/images/homepage/experience-bg.jpg');
  background-size: cover;
  background-position: center;
}

.reviews-bg {
  background-image: url('./assets/images/homepage/reviews-bg.jpg');
  background-size: cover;
  background-position: center;
}

.general-banner {
  background-image: url('./assets/images/banner-bg.jpg');
  background-size: cover;
  background-position: center;
}

.services-banner {
  background-image: url('./assets/images/servicepage/banner-bg.jpg');
  background-size: cover;
  background-position: center;
}

.about-banner {
  background-image: url('./assets/images/aboutpage/banner-bg.jpg');
  background-size: cover;
  background-position: right 30% bottom 75%;
}

.contact-banner {
  background-image: url('./assets/images/contactpage/contact-banner.jpg');
  background-size: cover;
  background-position: center;
}

.book-banner {
  background-image: url('./assets/images/bookpage/banner-bg.jpg');
  background-size: cover;
  background-position: center;
}

.letter-white {
  color: #fff;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}

.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  margin-top: 12px;
  margin-left: 6px;
  display: inline-block;
  text-align: center;
  line-height: 8px;
  opacity: 1;
  border-radius: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.swiper-pagination-bullet-active {
  color: #fff;
  background: #000;
}


@media only screen and (min-width: 1024px) {
  .about-banner {
    background-position: right 0 bottom 75%;
  }
}
