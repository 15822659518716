//
// ===== ===== ===== FADING ANIMATIONS ===== ===== =====
//
$fadeInUpOffset: 100%;
$fadeInRightOffset: -10%;
$fadeInDownOffset: -20%;
$fadeInLeftOffset: 10%;

// base fade-in animation properties
.fadeIn {
  opacity: 0;
  animation-duration: 1500ms;
  animation-fill-mode: forwards !important;
}

.animFadeIn {
  transition: opacity 3s ease, background-color 3s ease, color 3s ease;
}

.animFadeInUp {
  @extend .fadeIn;
  animation: fadeInUp;
  transform: translateY($fadeInUpOffset);
}
.animFadeInRight {
  @extend .fadeIn;
  animation: fadeInRight;
  transform: translateX($fadeInRightOffset);
}
.animFadeInDown {
  @extend .fadeIn;
  animation: fadeInDown;
  transform: translateY($fadeInDownOffset);
}
.animFadeInLeft {
  @extend .fadeIn;
  animation: fadeInLeft;
  transform: translateX($fadeInLeftOffset);
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY($fadeInUpOffset);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX($fadeInRightOffset);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY($fadeInDownOffset);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX($fadeInLeftOffset);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

//
// ===== ===== ===== SCALING ANIMATIONS ===== ===== =====
//

.animScaleUp {
  animation: scaleUp 500ms;
  animation-delay: 500ms;
  animation-fill-mode: forwards;
  transform: scale(0.75);
}

@keyframes scaleUp {
  0% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(1);
  }
}

.anim-progress-fill-trigger .anim-progress-fill {
  width: 0;

  animation: progressFill 2000ms;
  animation-fill-mode: forwards;
  /* transform: scaleX(0); */
}

@keyframes progressFill {
  from {
    width: 0;
    /* transform: scaleX(0); */
  }
  to {
    width: 100%;
    /* transform: scaleX(1); */
  }
}
